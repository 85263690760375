var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4 card-padding",style:({
      filter: _vm.darkmode
        ? 'drop-shadow(1px -1px 0px #5C6BC0) drop-shadow(-1px 1px 1px #0C0B10)'
        : '',
    }),attrs:{"dark":_vm.darkmode}},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('div',[_c('v-btn',{staticClass:"text-caption text-none font-weight-bold white--text",attrs:{"elevation":"0","small":"","dark":_vm.darkmode,"color":"indigo lighten-1","disabled":_vm.selectedTimestamps.length === 0},on:{"click":_vm.removeMultiple}},[_vm._v(" Delete Selected ")])],1),(_vm.historicalData[0])?_c('v-data-table',{staticClass:"table px-4",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.historicalData[0].data,"show-select":"","hide-default-footer":"","item-key":"_id","page":_vm.page,"mobile-breakpoint":"0","sort-by":"name","sort-desc":false},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item._id, "MM-dd-yyyy hh:mm aa"))+" ")])]}},{key:"item.average",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("to2Decimals")(item.average,_vm.round))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"text-none text-caption font-weight-bold white--text",attrs:{"elevation":"0","color":"red","icon":"","ripple":false},on:{"click":function($event){return _vm.remove([item._id])}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-trash-can ")])],1)]}}],null,false,364183503),model:{value:(_vm.selectedTimestamps),callback:function ($$v) {_vm.selectedTimestamps=$$v},expression:"selectedTimestamps"}}):_vm._e()],1),_c('div',{staticClass:"card-padding d-flex justify-end"},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"indigo lighten-1","length":_vm.pageCount,"circle":"","total-visible":_vm.$vuetify.breakpoint.smAndDown ? 4 : 8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }